import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { object, string, number, nativeEnum } from 'zod';
import { LoaderPage } from '../../components/LoaderPage/LoaderPage';
import { ProviderType } from './ThirdPartySignIn';
import { ThirdPartyAuthError } from './ThirdPartyAuthError';

const zod = { object, string, number, nativeEnum };

interface RedirectToExtensionProps {
  providerType: ProviderType | null;
  redirectUri: string | null;
  code: string | null;
}

const stateSchema = zod.object({
  redirectUri: zod.string().url(),
  providerType: zod.nativeEnum(ProviderType),
});

export const ThirdPartyAuthSuccess = () => {
  const [searchParams] = useSearchParams();

  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  if (error || errorDescription) {
    return <ThirdPartyAuthError error={error} errorDescription={errorDescription} />;
  }

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  if (!code || !state) throw new Error('Invalid params received from auth provider');

  const { redirectUri, providerType } = stateSchema.parse(JSON.parse(state));

  if (!redirectUri || !providerType) throw new Error('Auth provider returned invalid state');

  return <RedirectToExtension providerType={providerType} redirectUri={redirectUri} code={code} />;
};

function RedirectToExtension({ redirectUri, providerType, code }: RedirectToExtensionProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!redirectUri || !providerType || !code) return;

    // Uri need to be decoded before appending the code param.
    const decodedUri = decodeURIComponent(redirectUri);

    const redirectUrl = new URL(decodedUri);
    redirectUrl.searchParams.append('providerType', providerType);
    redirectUrl.searchParams.append('code', code);

    window.location.replace(redirectUrl.toString());
    navigate(`/extension/third-party/after-signin?providerType=${providerType}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUri, providerType, code]);

  return <LoaderPage />;
}
