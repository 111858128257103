import styled from 'styled-components';
import { ContentWrapper } from '../../components/common/ContentWrapper';
import { Label } from '../../components/common/Label';
import { Title } from '../../components/common/Title';
import { Button, ButtonProps } from '../../components/common/Button';
import { Divider } from '../../components/common/Divider';
import { InfoItem } from '../../components/common/InfoItem';
import { Heading } from './styled';
import { useMemo, useState } from 'react';
import Stripe from 'stripe';
import { useNavigate } from 'react-router-dom';
import { useNavigateToPayment } from '../../hooks/useNavigateToPayment';
import VisaIcon from './assets/visa.svg';
import MasterCardIcon from './assets/mastercard.svg';
import AmexIcon from './assets/amex.svg';
import { Text } from '../../components/common/Text';
import { PriceAlias } from '../CheckoutPage/types';
import { SubscriptionErrorType, useSubscriptionInfoProps } from './useSubscriptionInfoProps';
import { pluralize } from '../../utils/pluralize';
import UpgradeToEnterpriseForm from '../PricingPage/UpgradeToEnterpriseForm';

const cardNameToIconMap: Record<string, string> = {
  visa: VisaIcon,
  mastercard: MasterCardIcon,
  amex: AmexIcon,
};

const HintSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 32px;
  gap: 48px;

  & > button {
    margin-left: auto;
    align-self: flex-end;
  }
`;

const SubscriptionStatusText = styled(Text)`
  align-self: center;
  font-weight: 500;
  margin-bottom: 8px;
`;

const errorToStatusMessageMap = {
  cancelled: 'Cancelled',
  paymentFailed:
    "We couldn't process the last payment. Please check your payment details or contact us",
  seatsLimitExceeded: 'Paid seat limit exceeded, please update plan',
} as Record<SubscriptionErrorType, string>;

const SubscriptionStatus = ({ errors }: { errors: SubscriptionErrorType[] }) => {
  const statusMessages = errors.map((e) => errorToStatusMessageMap[e]).filter(Boolean);

  return (
    <div>
      {statusMessages.map((message, index) => (
        <SubscriptionStatusText key={`subscription-status-${index}`} $variant="error">
          {message}
        </SubscriptionStatusText>
      ))}
    </div>
  );
};

const intervalLabelsMap: Partial<Record<Stripe.Plan.Interval, string>> = {
  month: 'Monthly',
  year: 'Annualy',
};

const BillingInfoButton = ({ errors }: { errors: SubscriptionErrorType[] }) => {
  const navigateToBillingInfo = useNavigateToPayment();

  const buttonProps = useMemo(
    () =>
      (!!errors.length
        ? { $variant: 'primary', $filled: true }
        : { $variant: 'secondary', $filled: false }) as ButtonProps,
    [errors]
  );

  const buttonText = !errors.length
    ? 'Manage seats and billing'
    : errors.includes('cancelled')
      ? 'Renew plan'
      : errors.includes('paymentFailed')
        ? 'Update payment method'
        : errors.includes('paymentMethodMissing')
          ? 'Make payment'
          : errors.includes('seatsLimitExceeded')
            ? 'Update plan'
            : '';

  return (
    <Button {...buttonProps} $widthType="auto" onClick={navigateToBillingInfo}>
      {buttonText}
    </Button>
  );
};

const UpgradeButton = ({
  planAlias,
  isCancelled,
}: {
  planAlias: PriceAlias;
  isCancelled: boolean;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const isBusinessPlan = 'businessMonthly' === planAlias;
  const buttonText = isBusinessPlan && !isCancelled ? 'Upgrade to Enterprise' : 'Upgrade';

  const handleClick = () => {
    if (isBusinessPlan) {
      setOpen(true);
    } else {
      navigate('/portal/pricing');
    }
  };

  const buttonProps = (
    isBusinessPlan
      ? { $variant: 'secondary', $filled: false }
      : { $variant: 'primary', $filled: true }
  ) as ButtonProps;

  return (
    <>
      <Button {...buttonProps} onClick={handleClick}>
        {buttonText}
      </Button>
      <UpgradeToEnterpriseForm isOpen={open} onClose={() => setOpen(false)} />
    </>
  );
};

export const SubscriptionInfo = () => {
  const subInfo = useSubscriptionInfoProps();
  const paymentMethod = subInfo.paymentMethod;

  const cardBrand = paymentMethod?.card?.brand || 'other';

  return (
    <ContentWrapper $error={subInfo.isError}>
      <Heading>
        <HintSection>
          {subInfo.trialEndsIn && subInfo.trialEndsIn > 0 ? (
            <Label $variant={'warning'}>
              Trial ends in {subInfo.trialEndsIn} {pluralize(subInfo.trialEndsIn, 'day')}
            </Label>
          ) : (
            <Label>Current plan</Label>
          )}
        </HintSection>
        <TitleSection>
          <TitleWrapper>
            <Title>{subInfo.plan?.metadata?.name || 'No subscription'}</Title>
            <UpgradeButton
              isCancelled={subInfo.errors.includes('cancelled')}
              planAlias={(subInfo.plan?.metadata?.alias as PriceAlias) || 'free'}
            />
          </TitleWrapper>
          <SubscriptionStatus errors={subInfo.errors} />
        </TitleSection>
      </Heading>
      {subInfo.plan && subInfo.plan.metadata?.alias !== 'free' && (
        <>
          <Divider />
          <InfoSection>
            {!subInfo.validUntill ? (
              <>
                <InfoItem label="Interval">{intervalLabelsMap[subInfo.plan.interval]}</InfoItem>
                <InfoItem label="Renew on">{subInfo.billingCycleEnd}</InfoItem>
                <InfoItem label="Payment">
                  {subInfo.hasDiscount
                    ? subInfo.formattedDiscountedAmount
                    : subInfo.formattedPaymentAmount}
                </InfoItem>
                {paymentMethod && (
                  <InfoItem label="Method">
                    {cardBrand in cardNameToIconMap && (
                      <img src={cardNameToIconMap[cardBrand]} alt="Method" width={32} height={24} />
                    )}
                    <Text>{`•••• ${paymentMethod.card?.last4}`}</Text>
                  </InfoItem>
                )}
              </>
            ) : (
              <InfoItem label="Valid till">{subInfo.validUntill}</InfoItem>
            )}
            <BillingInfoButton errors={subInfo.errors} />
          </InfoSection>
        </>
      )}
    </ContentWrapper>
  );
};
