import { InputHTMLAttributes } from 'react';

import './Input.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  errors?: string[];
  label?: string;
}

export function Input({ errors, label, className, ...rest }: Props) {
  const classNames = ['Input'];
  if (className) classNames.push(className);

  const error = (errors && errors.length > 0 && errors.join(', ')) || null;

  const inputElement = <input className={classNames.join(' ')} {...rest} />;
  return (
    <div className="InputWrapper">
      {label && (
        <label aria-label={`${label} input`}>
          {label}
          {inputElement}
        </label>
      )}
      {!label && inputElement}
      {error && <span aria-errormessage={error}>{error}</span>}
    </div>
  );
}
