import { useContext } from 'react';
import { RootContext } from '../../components/PortalLayout/PortalLayout';
import { formatMoney } from '../../utils/formatMoney';
import Stripe from 'stripe';
import { formatTimestamp } from '../../utils/formatDate';

const calculateDaysUntil = (date: Date) => {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24));
};

const isOverSeatLimit = (members: unknown[], seats: number) => {
  return members.length > seats;
};

export type SubscriptionErrorType =
  | 'cancelled'
  | 'paymentFailed'
  | 'paymentMethodMissing'
  | 'seatsLimitExceeded';

type SubscriptionInfoProps = {
  plan: Stripe.Plan | null;
  formattedPaymentAmount: string | null;
  formattedDiscountedAmount: string | null;
  billingCycleEnd: string | null;
  cancelAt?: string | null;
  isError: boolean;
  errors: SubscriptionErrorType[];
  trialEndsIn: number | null;
  paymentMethod?: Stripe.PaymentMethod;
  validUntill: string | null;
  isOverLimit: boolean;
  hasDiscount: boolean;
};

export const useSubscriptionInfoProps = (): SubscriptionInfoProps => {
  const { subscription, paymentMethod, members } = useContext(RootContext);

  if (!subscription) {
    return {
      plan: null,
      billingCycleEnd: null,
      formattedPaymentAmount: null,
      formattedDiscountedAmount: null,
      cancelAt: null,
      isError: true,
      isOverLimit: false,
      errors: [],
      validUntill: null,
      trialEndsIn: null,
      paymentMethod: undefined,
      hasDiscount: false,
    };
  }

  const errors: SubscriptionErrorType[] = [];

  const plan: Stripe.Plan = subscription.plan;
  const billingCycleEnd = formatTimestamp(subscription.current_period_end * 1000);

  const qty = subscription.quantity;
  const paymentAmount = qty * (plan.amount || 0);
  const formattedPaymentAmount = formatMoney(paymentAmount, true);

  // Handle discount if present
  const hasDiscount = !!subscription.discount;
  const discountPercentage = hasDiscount
    ? subscription.discount?.coupon?.percent_off || null
    : null;

  // Calculate discounted amount if there's a discount
  let formattedDiscountedAmount = null;
  if (hasDiscount && discountPercentage) {
    const discountMultiplier = (100 - discountPercentage) / 100;
    const discountedAmount = paymentAmount * discountMultiplier;
    formattedDiscountedAmount = formatMoney(discountedAmount, true);
  }

  const cancelAt = subscription.cancel_at ? formatTimestamp(subscription.cancel_at * 1000) : null;

  const trialEndsIn = subscription.trial_end
    ? calculateDaysUntil(new Date(subscription.trial_end * 1000))
    : null;

  const validUntillTimestamp =
    trialEndsIn && !paymentMethod ? subscription.trial_end : subscription.cancel_at;

  const validUntill = validUntillTimestamp ? formatTimestamp(validUntillTimestamp * 1000) : null;

  const isPaidPlan = subscription?.plan?.metadata?.alias !== 'free' && !trialEndsIn;

  const isOverLimit = isPaidPlan && isOverSeatLimit(members || [], qty);
  if (isOverLimit) {
    errors.push('seatsLimitExceeded');
  }

  if (subscription.cancel_at_period_end) {
    errors.push('cancelled');
  }

  if (!paymentMethod) {
    errors.push('paymentMethodMissing');
  }

  return {
    plan,
    formattedPaymentAmount,
    formattedDiscountedAmount,
    billingCycleEnd,
    cancelAt,
    isError: isOverLimit || subscription.cancel_at_period_end,
    errors,
    trialEndsIn,
    paymentMethod,
    validUntill,
    isOverLimit,
    hasDiscount,
  };
};
