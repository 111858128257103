import styled from 'styled-components';
import { Title } from '../../components/common/Title';
import { Button } from '../../components/common/Button';
import { PageContainer } from '../../components/common/Layout';
import Header from '../../components/PortalLayout/Header';
import { useNavigate } from 'react-router';

const ERROR_TEXTS = {
  access_denied: 'You have denied an access for Zencoder',
};

interface Props {
  error: string | null;
  errorDescription: string | null;
}

export function ThirdPartyAuthError({ error, errorDescription }: Props) {
  const navigate = useNavigate();
  if (!error || !errorDescription) {
    throw new Error(errorDescription || 'Unknown error');
  }

  const userError = ERROR_TEXTS[error as keyof typeof ERROR_TEXTS] || 'Unknown error';

  return (
    <PageContainer>
      <Header />
      <Wrapper>
        <Title>{userError}</Title>
        <Button $widthType="auto" $variant="secondary" onClick={() => navigate('/dashboard')}>
          Open Admin Panel
        </Button>
      </Wrapper>
    </PageContainer>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  height: 500px;

  padding: 64px;
  background-color: #fff;
  border-radius: 16px;
`;
