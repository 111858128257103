import { FormEventHandler, useCallback, useState } from 'react';
import { useAuth } from '@frontegg/react';
import { useLoginActions } from '@frontegg/react-hooks';
import { type inferFlattenedErrors, object, string } from 'zod';
import { LoaderPage } from '../LoaderPage/LoaderPage';
import { Input } from '../Input/Input';

import './TermsOfService.scss';

const zod = { object, string };

const LOGOUT_REDIRECT_URL = '/account/login';

const companyNameErrorMessage = 'Company name is required';
const formSchema = zod.object({
  companyName: zod.string({ message: companyNameErrorMessage }).nonempty(companyNameErrorMessage),
});
type SchemaErrors = inferFlattenedErrors<typeof formSchema>;

export default function TermsOfService() {
  const { user } = useAuth();
  const { logout } = useLoginActions();

  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<null | SchemaErrors>(null);

  const isFormDisabled = isLoading;
  const isSubmitDisabled = isFormDisabled || Boolean(validationErrors);

  const validate = useCallback(function validate(form: HTMLFormElement) {
    const fd = new FormData(form);
    const fdObject = Object.fromEntries(fd.entries());
    const dataParsed = formSchema.safeParse(fdObject); // maybe use zod-form-data ?
    const validationState = dataParsed.error?.flatten() || null;
    setValidationErrors(validationState);
    return dataParsed;
  }, []);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const dataParsed = validate(e.currentTarget);
      if (!dataParsed.success) return false;

      const url = '/accept_terms';
      setIsLoading(true);
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(dataParsed.data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user!.accessToken}`,
        },
      }).then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          window.location.reload();
        } else {
          setIsLoading(false);
        }
      });
      return false;
    },
    [user, validate]
  );

  const handleChange: FormEventHandler<HTMLFieldSetElement> = useCallback(
    function (e) {
      e.preventDefault();
      if (!e.currentTarget.form) throw new Error('Wrong callback node');
      validate(e.currentTarget.form);
    },
    [validate]
  );

  function handleSignOut() {
    logout();
  }

  if (!user) {
    window.location.replace(LOGOUT_REDIRECT_URL);
    return <LoaderPage></LoaderPage>;
  }

  return (
    <div className={'TermsOfServiceBackground'}>
      <form className={'TermsOfServiceModalWrapper'} onSubmit={handleSubmit}>
        <fieldset
          disabled={isFormDisabled}
          onChange={handleChange}
          className={'TermsOfServiceModal'}
          style={{ border: 'none' }}
        >
          <h1 className="TermsOfServiceHeader">Last things to cover up</h1>
          <div className={'TermsOfServiceContent'}>
            <Input
              type="text"
              required
              name="companyName"
              label="Company name"
              placeholder="Zencoder"
              errors={validationErrors?.fieldErrors.companyName}
            />
            <h2 className={'TermsOfServiceSubHeader'}>Terms and Privacy Policy</h2>
            <p className={'TermsOfServiceBody'}>
              Please review our{' '}
              <a
                href={'https://zencoder.ai/terms-of-service'}
                target={'_blank'}
                rel={'noreferrer'}
                className={'TermsOfServiceLink'}
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={'https://zencoder.ai/privacy-policy'}
                target={'_blank'}
                rel={'noreferrer'}
                className={'TermsOfServiceLink'}
              >
                Privacy
                <br />
                Policy
              </a>
              . By clicking “Accept”, you agree to our terms
              <br />
              and policies.
            </p>
          </div>
          <div className={'TermsOfServiceActions'}>
            <button
              type="submit"
              className={'TermsOfServiceAcceptButton'}
              disabled={isSubmitDisabled}
            >
              Accept and Continue
            </button>
            <button className={'TermsOfServiceDeclineButton'} onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
