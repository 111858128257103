import { useContext, useEffect, useMemo, useState } from 'react';
import { useApiClient } from './useApiClient/useApiClient';
import { Plan, PlanAlias, PlanProps } from '../pages/PricingPage/types';
import { RootContext, SubscriptionInfo } from '../components/PortalLayout/PortalLayout';
import { PriceAlias } from '../pages/CheckoutPage/types';
import { ButtonProps } from '../components/common/Button';
import { formatMoney } from '../utils/formatMoney';

const allowedPlans: PlanAlias[] = ['free', 'business', 'enterprise'];

const seatsAllowanceText = {
  free: 'Up to 10 seats',
  business: 'Up to 200 seats',
  enterprise: '∞ Unlimited seats ',
};

const composePlanProps = (
  plans: Plan[],
  subscription: SubscriptionInfo['subscription']
): PlanProps[] => {
  let activePlanLevel: number = -1;

  const planProps = plans
    .filter((p) => allowedPlans.includes(p.metadata.alias))
    .sort((a, b) => parseInt(a.metadata.level) - parseInt(b.metadata.level))
    .map((plan) => {
      const priceIds = plan.price.map((price) => price.id);

      const price = plan.price.find((p) => p.billingCycle === 'month') || plan.price[0];

      const planAlias = plan.metadata.alias;
      const priceText = formatMoney(price?.unitAmount || 0, true);

      const isCurrentPlan = subscription ? priceIds.includes(subscription?.plan?.id) : false;

      const planLevel = parseInt(plan.metadata.level);
      activePlanLevel = isCurrentPlan ? planLevel : activePlanLevel;

      const buttonText = isCurrentPlan
        ? 'Active plan'
        : plan.metadata.alias === 'enterprise'
          ? 'Contact us'
          : 'Upgrade';

      return {
        plan,
        isActive: isCurrentPlan,
        buttonText,
        priceText,
        buttonProps: {
          $widthType: 'full',
          $variant: planAlias === 'enterprise' ? 'secondary' : 'primary',
          $filled: planAlias === 'enterprise' ? false : !isCurrentPlan,
          disabled: isCurrentPlan,
        } as ButtonProps,
        priceAlias: price.metadata.alias as PriceAlias,
        openContactForm: plan.metadata.alias === 'enterprise',
        seatsAllowance: seatsAllowanceText[plan.metadata.alias],
      };
    });

  const planPropsWithUpgradeAllowed = planProps.map((planProp) => {
    const planLevel = parseInt(planProp.plan.metadata.level);
    return {
      ...planProp,
      isUpgradeAllowed: planLevel > activePlanLevel,
    };
  });

  return planPropsWithUpgradeAllowed;
};

export const useStripePlans = (): { plans: Plan[]; loading: boolean } => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchPlans = async () => {
      const response = await apiClient.get<Plan[]>(`/api/stripe/plans`);

      response && setPlans(response);
      setLoading(false);
    };

    fetchPlans();
  }, [apiClient]);

  return { plans, loading };
};

export const usePlansWithProps = (): { plans: PlanProps[]; loading: boolean } => {
  const { subscription, plans = [] } = useContext(RootContext);

  const planProps = useMemo(() => composePlanProps(plans, subscription), [plans, subscription]);

  return { plans: planProps, loading: !plans.length };
};
